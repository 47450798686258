import React from "react";
import { Link } from "@reach/router";

const Nav = () => {
  const links = [
    {
      href: "https://github.com/zeit/next.js",
      label: "Next.js is cool"
    }
  ];

  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <ul>
          {links.map(({ href, label }, i) => (
            <li key={`${i}-${href}`}>
              <a href={href}>{label}</a>
            </li>
          ))}
        </ul>
      </ul>

      <style>{`
        :global(body) {
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, Avenir Next, Avenir,
            Helvetica, sans-serif;
        }
        nav {
          text-align: center;
        }
        ul {
          display: flex;
          justify-content: space-between;
        }
        nav > ul {
          padding: 4px 16px;
        }
        li {
          display: flex;
          padding: 6px 8px;
        }
        a {
          color: #067df7;
          text-decoration: none;
          font-size: 13px;
        }
      `}</style>
    </nav>
  );
};

export default Nav;
