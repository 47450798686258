import React from "react";
import Nav from "Components/Nav/Nav";

const Index = () => {
  return (
    <div>
      <Nav />
      <div className="hero">
        <h1 className="title">Welcome to Tyler Hellner.com</h1>
        <p className="description">
          <code>Pardon my dust while I build this site</code>
        </p>
      </div>
      <style>{`
        .hero {
          width: 100%;
          color: #333;
        }
        code {
          font-size: .875em;
          margin-left: 23.5em;
          font-style: italic;
        }
        .title {
          margin: 0 0 -1.25rem;
          width: 100%;
          padding-top: 80px;
          font-size: 48px;
        }
        .title,
        .description {
          text-align: center;
        }
        .row {
          max-width: 880px;
          margin: 80px auto 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }
      `}</style>
    </div>
  );
};

export default Index;
