import React from "react";
import "./App.css";
import { Router } from "@reach/router";

import Home from "Pages/Home";

function App() {
  return (
    <div className="App">
      <Router>
        <Home path={"/"} />
      </Router>
    </div>
  );
}

export default App;
